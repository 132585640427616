export class UserAccount{
    
    id:number;

	username:string;

	password:string;

	role:string;

	active:boolean;

	feature:string;
    
}